const BASE_URL = "https://dev-single-payment-api.promrub.comr";
// const BASE_URL = 'https://localhost:7279'

const API = {
  URL_AUTHORIZATION:  `${BASE_URL}/v1/api/Authorization/org`,
  URL_ORGANIZATION:  `${BASE_URL}/v1/api/Organization/org`,
  URL_CUSTOMER:  `${BASE_URL}/v1/api/Customer/org`,

  URL_TAX_REPORT: `${BASE_URL}/v1/api/TaxReport/org`,
  URL_RECEIVE_PAYMENT: `${BASE_URL}/v1/api/ReceivePayment/org`,
}

export default API;